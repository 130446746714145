<template>
  <div>
    <div class="card mb-4 mt-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h6 class="mb-0">
          <fa-icon
            :icon="['fa', 'key']"
            class="mr-3 text-secondary"
          />Pengaturan Akun
        </h6>
        <button @click="action" class="btn btn-sm btn-primary">
          <fa-icon :icon="['fa', 'save']" class="mr-2" />Simpan
        </button>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <label for="nomor-registrasi" class="col-sm-3 col-form-label"
            >Nomor Registrasi</label
          >
          <div class="col-sm-9">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <input
                  type="text"
                  :value="isian.no_registrasi"
                  readonly
                  id="nomor-registrasi"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="email" class="col-sm-3 col-form-label">E-Mail</label>
          <div class="col-sm-9">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <input
                  type="email"
                  name="E-Mail"
                  v-validate="'required|email'"
                  :class="{ 'is-invalid': errors.has('E-Mail') }"
                  v-model="isian.email"
                  id="email"
                  class="form-control form-control-sm"
                  placeholder="Masukan E-Mail"
                />
                <div
                  v-show="errors.has('E-Mail')"
                  class="invalid-feedback"
                  v-text="errors.first('E-Mail')"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="phone" class="col-sm-3 col-form-label">Phone</label>
          <div class="col-sm-9">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <input
                  type="phone"
                  name="Phone"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Phone') }"
                  v-model="isian.phone"
                  id="phone"
                  class="form-control form-control-sm"
                  placeholder="+62xxxxxxxxxx"
                />
                <div
                  v-show="errors.has('Phone')"
                  class="invalid-feedback"
                  v-text="errors.first('Phone')"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="form-group row">
          <label for="password" class="col-sm-3 col-form-label"
            >Kata Sandi</label
          >
          <div class="col-sm-9">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <input
                  type="password"
                  name="Kata Sandi"
                  v-validate="'required|min:6'"
                  :class="{ 'is-invalid': errors.has('Kata Sandi') }"
                  v-model="isian.password"
                  id="password"
                  class="form-control form-control-sm"
                  placeholder="Masukan Kata sandi"
                  ref="Kata Sandi"
                />
                <div
                  v-show="errors.has('Kata Sandi')"
                  class="invalid-feedback"
                  v-text="errors.first('Kata Sandi')"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="konfirm-password" class="col-sm-3 col-form-label"
            >Konfirmasi Kata Sandi</label
          >
          <div class="col-sm-9">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <input
                  type="password"
                  name="Konfirmasi Kata Sandi"
                  v-validate="'required|confirmed:Kata Sandi'"
                  :class="{ 'is-invalid': errors.has('Konfirmasi Kata Sandi') }"
                  v-model="isian.konfirm_password"
                  id="konfirm-password"
                  class="form-control form-control-sm"
                  placeholder="Konfirmasi kata sandi"
                  data-vv-as="Kata Sandi"
                />
                <div
                  v-show="errors.has('Konfirmasi Kata Sandi')"
                  class="invalid-feedback"
                  v-text="errors.first('Konfirmasi Kata Sandi')"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["biodatas"],
  data() {
    return {
      isian: {
        no_registrasi: "",
        password: "",
        konfirm_password: "",
      },
    };
  },

  methods: {
    init() {
      if (this.biodatas.akun_pendaftar) {
        this.isian.no_registrasi = this.biodatas.akun_pendaftar.no_registrasi;
        this.isian.email = this.biodatas.akun_pendaftar.email;
        this.isian.phone = this.biodatas.akun_pendaftar.phone;
        this.$validator.reset();
      }
    },
    action() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // this.$store.commit("setLoading", true);
          this.$axios
            .put("/pendaftar/" + this.biodatas.uuid + "/akun", {
              email: this.isian.email,
              phone: this.isian.phone,
              password: this.isian.password,
            })
            .then((res) => {
              this.$root.$emit("setNotif", {
                title: res.data.status,
                message: res.data.deskripsi,
                type: "success",
              });
              this.isian = {
                password: "",
                konfirm_password: "",
              };
              this.$validator.reset();
              this.init();
              // this.$store.commit("setLoading", false);
            })
            .catch(() => {})
            .finally(() => {
              this.$store.commit("setLoading", false);
            });
        }
      });
    },
  },
  watch: {
    biodatas: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },

  mounted() {
    this.$validator.reset();
    this.init();
  },
};
</script>
